import { regexCurrency } from './regex';
import dayjs from 'dayjs';
import isTodayPlugin from 'dayjs/plugin/isToday';
import 'dayjs/locale/id';
import { getToken } from 'next-auth/jwt';
import {
  EnumProjectType,
  EnumOrderStatus,
  NotificationTypeEnum,
  EnumOrderMaterial,
  PartnerType,
  EnumPaymentStatus,
  EnumStatusOrderOffer,
} from '@sobat-bangun/entities';
import { match } from 'ts-pattern';
import * as printJS from 'print-js';
import { GetServerSideProps, GetServerSidePropsContext, GetServerSidePropsResult, NextApiRequest } from 'next/types';
import axios, { AxiosError } from 'axios';
import { NextRequest } from 'next/server';
import { warn } from 'console';

export type TPartnerStatus = 'OPEN' | 'INPROGRESS' | 'VERIFIED' | 'REJECTED';

dayjs.extend(isTodayPlugin);

export const fileSizeMaxValidator = (fileList: FileList): boolean => {
  const MAX_FILE_SIZE = 20;
  let valid = true;

  if (fileList) {
    Array.from(fileList).forEach((_file) => {
      if (_file.size / 1024 / 1024 > MAX_FILE_SIZE) {
        valid = false;
      }
    });
  }
  return valid;
};

export const fileTypeImageValidator = (fileList: FileList) => {
  const IMAGE_TYPES = ['jpg', 'jpeg', 'png'];
  let valid = true;

  if (fileList) {
    Array.from(fileList).forEach((_file) => {
      if (!IMAGE_TYPES.includes(_file.type.slice(_file.type.search('/') + 1))) {
        valid = false;
      }
    });
  }
  return valid;
};

export const fileTypePDFValidator = (fileList: FileList) => {
  let valid = false;

  if (fileList) {
    Array.from(fileList).forEach((_file) => {
      if (_file.type === 'application/pdf') {
        valid = true;
      }
    });
  }
  return valid;
};

export const getPartnerTypeOptions = () => {
  return [
    {
      label: 'Kontraktor',
      value: PartnerType.CONTRACTOR,
    },
    {
      label: 'Arsitektur',
      value: PartnerType.ARCHITECT,
    },
    {
      label: 'Developer',
      value: PartnerType.DEVELOPER,
    },
    {
      label: 'Mandor / Tukang',
      value: PartnerType.CRAFTMAN,
    },
    {
      label: 'Tek. Kontruksi',
      value: PartnerType.CONSTRUCTION_TECHNOLOGY,
    },
  ];
};

export function makeTitle(slug: string) {
  const words = slug?.split('-');

  for (let i = 0; i < words?.length; i++) {
    const word = words[i];
    words[i] = word.charAt(0).toUpperCase() + word.slice(1);
  }

  return words?.join(' ');
}

export const getRolePartnerValue = (partnerRole: string) => {
  let rolePartner = '';

  switch (partnerRole) {
    case 'Kontraktor':
      rolePartner = 'CONTRACTOR';
      break;

    case 'Arsitek':
      rolePartner = 'ARCHITECT';
      break;

    case 'Mandor ':
      rolePartner = 'CRAFTMAN';
      break;

    case 'Tek. Konstruksi':
      rolePartner = 'CONSTRUCTION_TECHNOLOGY';
      break;

    case 'Developer':
      rolePartner = 'DEVELOPER';
      break;
  }

  return rolePartner;
};

export const getPartnerTypeTranslation = (partnerType: string) => {
  let translated = '';

  switch (partnerType) {
    case PartnerType.CONTRACTOR:
      translated = 'Kontraktor';
      break;

    case PartnerType.ARCHITECT:
      translated = 'Arsitek';
      break;

    case PartnerType.CRAFTMAN:
      translated = 'Tukang / Mandor';
      break;

    case PartnerType.CONSTRUCTION_TECHNOLOGY:
      translated = 'Tek. Konstruksi';
      break;

    case PartnerType.DEVELOPER:
      translated = 'Developer';
      break;
  }

  return translated;
};

export const MitraPartnerStatus: { value: TPartnerStatus; label: string }[] = [
  { value: 'OPEN', label: 'Menunggu' }, // TODO: di response sama di excel beda
  { value: 'INPROGRESS', label: 'Dalam proses' },
  { value: 'VERIFIED', label: 'Disetujui' }, // TODO: di response sama di excel beda
  { value: 'REJECTED', label: 'Ditolak' },
];

export const mitraFindPartnerStatus = (value: string): { value: TPartnerStatus; label: string } | undefined => {
  return MitraPartnerStatus.find((data) => {
    return data.value === value;
  });
};

export function stringToSlug(str: string): string {
  str = str.toLowerCase().trim();

  // Replace any special characters with a space
  str = str.replace(/[^\w\s]/gi, ' ');

  // Replace all white space with a dash
  str = str.replace(/\s+/g, '-');

  return str;
}

export function slugToString(slug: string): string {
  slug = slug.trim();

  // Replace all dashes with a space
  slug = slug.replace(/-/g, ' ');

  // Capitalize the first letter of each word
  slug = slug.replace(/\b\w/g, (char) => char.toUpperCase());

  return slug;
}

export function capitalize(text: string, props?: { withDash?: boolean; onlyFirstLetter?: boolean }): string | undefined {
  if (!text) return;

  if (text.includes('-') || text.includes('_')) {
    if (props?.withDash) {
      const removedDash = text.split('-');
      const capitalizedWords = removedDash.map((item) => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase());
      return capitalizedWords.join('-');
    }

    const removedDash = text.includes('-') ? text.split('-').join(' ') : text.split('_').join(' ');
    return removedDash.charAt(0).toUpperCase() + removedDash.slice(1).toLowerCase();
  }

  if (props?.onlyFirstLetter) return text.charAt(0).toUpperCase() + text.slice(1);

  return text.charAt(0) + text.slice(1).toLowerCase();
}

export const isFirstLetterLowerCase = (text: string) => text.startsWith(text.charAt(0).toLowerCase());

export const toCurrency = (value: string, separator = '.') => {
  return value.replace(regexCurrency, `$1${separator}`);
};

// reference: https://github.com/react-component/upload/blob/master/src/attr-accept.ts
export const acceptFileTypes = (file: File, acceptedFiles: string | string[]) => {
  if (file && acceptedFiles) {
    const acceptedFilesArray = Array.isArray(acceptedFiles) ? acceptedFiles : acceptedFiles.split(',');
    const fileName = file.name || '';
    const mimeType = file.type || '';
    const baseMimeType = mimeType.replace(/\/.*$/, '');

    return acceptedFilesArray.some((type) => {
      const validType = type.trim();
      // This is something like */*,*  allow all files
      if (/^\*(\/\*)?$/.test(type)) {
        return true;
      }

      // like .jpg, .png
      if (validType.charAt(0) === '.') {
        const lowerFileName = fileName.toLowerCase();
        const lowerType = validType.toLowerCase();

        let affixList = [lowerType];
        if (lowerType === '.jpg' || lowerType === '.jpeg') {
          affixList = ['.jpg', '.jpeg'];
        }

        return affixList.some((affix) => lowerFileName.endsWith(affix));
      }

      // This is something like a image/* mime type
      if (/\/\*$/.test(validType)) {
        return baseMimeType === validType.replace(/\/.*$/, '');
      }

      // Full match
      if (mimeType === validType) {
        return true;
      }

      // Invalidate type should skip
      if (/^\w+$/.test(validType)) {
        console.warn(`Upload takes an invalidate 'accept' type '${validType}'.Skip for check.`);
        return true;
      }

      return false;
    });
  }
  return true;
};

export const formattingRowNumber = (index: number, page: number, per_page: number) => {
  const formatRowNumber = page * per_page - per_page;

  return `${formatRowNumber + index + 1}`;
};

export const formatToRupiah = (number?: number) => (number === null || number === undefined ? '-' : new Intl.NumberFormat('id-ID').format(number));

export const strapiImageLoader = ({ src, width, quality }: { src: string; width: number; quality: number }) => {
  return `${process.env.NEXT_PUBLIC_STRAPI_IMAGE_URL}/${src}?w=${width}&q=${quality || 75}`;
};

// Format DD/MM/YYYY
export const formatDate = (date: string, format = 'DD/MM/YYYY') => (date ? dayjs(date).format(format) : null);

export const formatDateLocal = (date: string) => (date ? dayjs(date).locale('id').format('DD MMMM YYYY HH:mm') : null);

export const isFutureTime = (hour: string, minute: string) => {
  const [hourNow, minuteNow] = dayjs().format('HH:mm').split(':');

  if (parseInt(hour) > parseInt(hourNow)) return true;
  if (parseInt(hour) === parseInt(hourNow)) return parseInt(minute) > parseInt(minuteNow);
  else return false;
};

export const isToday = (date?: string | Date) => (date ? dayjs(date).isToday() : false);

type TNumberPhoneFormatterProps = {
  phone: string;
  startsWith: '0' | '62';
};

export const numberPhoneFormatter = ({ phone, startsWith }: TNumberPhoneFormatterProps) => {
  if (!phone) return;

  if (startsWith === '62') {
    return phone.startsWith('62') ? `0${phone.slice(2)}` : phone;
  }

  return phone.startsWith('0') ? `62${phone.slice(1)}` : phone;
};

export const getAgentDetect = (userAgent: NavigatorID['userAgent']) => {
  const isAndroid = () => Boolean(userAgent.match(/Android/i));
  const isIos = () => Boolean(userAgent.match(/iPhone|iPad|iPod/i));
  const isOpera = () => Boolean(userAgent.match(/Opera Mini/i));
  const isWindows = () => Boolean(userAgent.match(/IEMobile/i));
  const isSSR = () => Boolean(userAgent.match(/SSR/i));
  const isMobile = () => Boolean(isAndroid() || isIos() || isOpera() || isWindows());
  const isDesktop = () => Boolean(!isMobile() && !isSSR());
  return {
    isMobile,
    isDesktop,
    isAndroid,
    isIos,
    isSSR,
  };
};

export const printJs = (props: printJS.Configuration) => {
  import('print-js').then((printJS) => {
    (printJS as any).default(props);
  });
};

export function formatToSentenceCase(text: string): string {
  if (!text) {
    return '';
  }

  const words = text.trim().split(/\s+/);

  const formattedWords = words.map((word) => {
    const firstChar = word.charAt(0).toUpperCase();
    const restOfString = word.slice(1).toLowerCase();
    return `${firstChar}${restOfString}`;
  });

  return formattedWords.join(' ');
}

export const checkRequiredFieldsIsNull = (data: any, fields: string[]) => {
  if (data) {
    for (const field of fields) {
      if (!Object.prototype.hasOwnProperty.call(data, field) || data[field] === null || data[field] === undefined || data[field] === '') {
        return true;
      }
    }
    return false;
  }
  return true;
};

export const OrderMaterialStatusMapper = (val: string | unknown | undefined) =>
  match(val)
    .with(EnumOrderMaterial.GENERAL, () => 'Non Proyek')
    .with(EnumOrderMaterial.GENERAL_OLD, () => 'Non Proyek')
    .with(EnumOrderMaterial.PROJECT, () => 'Proyek')
    .with(EnumOrderMaterial.PROJECT_OLD, () => 'Proyek')
    .otherwise(() => '-');

export const ProjectTypeMapper = (val?: string | unknown) =>
  match(val)
    .with(EnumProjectType.Design, () => 'Desain')
    .with(EnumProjectType.Material, () => 'Material')
    .with(EnumProjectType.BuildHouse, () => 'Bangun Rumah')
    .with(EnumProjectType.Renovation, () => 'Renovasi')
    .with(EnumProjectType.AdditionalTechnology, () => 'Teknologi Tambahan')
    .otherwise(() => '-');

export const OrderStatusMapper = (val?: string | unknown) =>
  match(val)
    .with(EnumOrderStatus.OPEN, () => 'Penawaran Dibuka')
    .with(EnumOrderStatus.TENDERING, () => 'Tender')
    .with(EnumOrderStatus.PARTNER_SELECTED, () => 'Mitra Terpilih')
    .with(EnumOrderStatus.VENDOR_SELECTED, () => 'Penyuplai Terpilih')
    .with(EnumOrderStatus.ADMIN_APPROVED, () => 'Disetujui Admin')
    .with(EnumOrderStatus.CUSTOMER_APPROVED, () => 'Disetujui Mitra')
    .with(EnumOrderStatus.CUSTOMER_REJECTED, () => 'Ditolak Mitra')
    .with(EnumOrderStatus.PAID, () => 'Dibayar')
    .with(EnumOrderStatus.UNPAID, () => 'Belum Dibayar')
    .with(EnumOrderStatus.SIGNING, () => 'Penandatanganan')
    .with(EnumOrderStatus.SHIPPING, () => 'Dikirim')
    .with(EnumOrderStatus.COMPLETE, () => 'Selesai')
    .with(EnumOrderStatus.CANCELED, () => 'Dibatalkan')
    .with(EnumOrderStatus.ADMIN_MARKUP, () => 'Harga Jual Telah Diperbarui')
    .with(EnumOrderStatus.INPROGRESS, () => 'Sedang Diproses')
    .otherwise(() => '-');

export const OrderStatusNonProjectMapper = (val?: string | unknown) =>
  match(val)
    .with(EnumOrderStatus.OPEN, () => 'Penawaran Dibuka')
    .with(EnumOrderStatus.TENDERING, () => 'Tender')
    .with(EnumOrderStatus.PARTNER_SELECTED, () => 'Mitra Terpilih')
    .with(EnumOrderStatus.VENDOR_SELECTED, () => 'Penyuplai Terpilih')
    .with(EnumOrderStatus.ADMIN_APPROVED, () => 'Disetujui Admin')
    .with(EnumOrderStatus.CUSTOMER_APPROVED, () => 'Disetujui Pelanggan')
    .with(EnumOrderStatus.CUSTOMER_REJECTED, () => 'Ditolak Pelanggan')
    .with(EnumOrderStatus.PAID, () => 'Dibayar')
    .with(EnumOrderStatus.UNPAID, () => 'Belum Dibayar')
    .with(EnumOrderStatus.SIGNING, () => 'Penadatanganan')
    .with(EnumOrderStatus.SHIPPING, () => 'Dikirim')
    .with(EnumOrderStatus.COMPLETE, () => 'Selesai')
    .with(EnumOrderStatus.CANCELED, () => 'Dibatalkan')
    .with(EnumOrderStatus.ADMIN_MARKUP, () => 'Harga Jual Telah Diperbarui')
    .with(EnumOrderStatus.INPROGRESS, () => 'Sedang Diproses')
    .otherwise(() => '-');

//Status Offer Mapper
export const OrderOfferStatusMapper = (val?: string | unknown) =>
  match(val)
    .with(EnumStatusOrderOffer.ACCEPTED_BY_CUSTOMER, () => 'Diterima oleh Pelanggan')
    .with(EnumStatusOrderOffer.ACCEPTED_BY_ADMIN, () => 'Diterima oleh Admin')
    .with(EnumStatusOrderOffer.REJECTED_BY_CUSTOMER, () => 'Ditolak oleh Pelanggan')
    .with(EnumStatusOrderOffer.REJECTED_BY_ADMIN, () => 'Ditolak oleh Admin')
    .with(EnumStatusOrderOffer.SEND_TO_CUSTOMER, () => 'Dikirim ke Pelanggan')
    .with(EnumStatusOrderOffer.PENDING, () => 'Menunggu Konfirmasi')
    .with(EnumStatusOrderOffer.ACCEPTED, () => 'Diterima')
    .with(EnumStatusOrderOffer.REJECTED, () => 'Ditolak Mitra')
    .with(EnumStatusOrderOffer.CANCELED, () => 'Dibatalkan')
    .otherwise(() => '-');

export const OrderStatusDisabled = (val?: string | unknown) =>
  match(val)
    .with(EnumOrderStatus.CUSTOMER_APPROVED, () => false)
    .with(EnumOrderStatus.PAID, () => false)
    .with(EnumOrderStatus.SHIPPING, () => false)
    .with(EnumOrderStatus.COMPLETE, () => false)
    .otherwise(() => true);

export const OrderStatusDisabledVendor = (val?: string | unknown) =>
  match(val)
    .with(EnumOrderStatus.TENDERING, () => true)
    .with(EnumOrderStatus.VENDOR_SELECTED, () => true)
    .with(EnumOrderStatus.CUSTOMER_APPROVED, () => true)
    .with(EnumOrderStatus.CUSTOMER_REJECTED, () => true)
    .with(EnumOrderStatus.PAID, () => true)
    .with(EnumOrderStatus.SHIPPING, () => true)
    .with(EnumOrderStatus.COMPLETE, () => true)
    .otherwise(() => false);

export const OrderStatusDisabledProject = (val?: string | unknown) =>
  match(val)
    .with(EnumOrderStatus.PAID, () => true)
    .with(EnumOrderStatus.SHIPPING, () => true)
    .with(EnumOrderStatus.COMPLETE, () => true)
    .otherwise(() => false);

export const OrderStatusDisabledNonProject = (val?: string | unknown) =>
  match(val)
    .with(EnumOrderStatus.SHIPPING, () => true)
    .with(EnumOrderStatus.COMPLETE, () => true)
    .otherwise(() => false);

export const NotificationTypeMapper = (type?: string) =>
  match(type)
    .with(NotificationTypeEnum.ReferralUsed, () => 'Kode referral telah digunakan!')
    .with(NotificationTypeEnum.ReferralMaxUsed, () => 'Kode referral mencapai batas penggunaan maksimal!')

    .with(NotificationTypeEnum.ApprovedDocumentForAccountPartner, () => 'Dokumen diterima!')
    .with(NotificationTypeEnum.RejectedDocumentForAccountPartner, () => 'Dokumen ditolak!')

    .with(NotificationTypeEnum.SigningPKSAdminToPartner, () => 'Penandatanganan dokumen PKS')
    .with(NotificationTypeEnum.SigningPKSPartnerToCustomer, () => 'Penandatanganan dokumen PKS')
    .with(NotificationTypeEnum.SigningPKSPartnerToAdmin, () => 'Penandatanganan dokumen PKS')

    .with(NotificationTypeEnum.AssignedNewOrder, () => 'Pesanan baru telah masuk!')
    .with(NotificationTypeEnum.PartnerRejectOrder, () => 'Penolakan pesanan!')
    .with(NotificationTypeEnum.PartnerApproveOrder, () => 'Persetujuan pesanan!')
    .with(NotificationTypeEnum.BiddingClosed, () => 'Proses penawaran akan ditutup!')

    .with(NotificationTypeEnum.OfferingMaterialFromCustomer, () => 'Pengajuan Form Beli Material Baru!')
    .with(NotificationTypeEnum.OfferingMaterialFromPartner, () => 'Pengajuan Form Beli Material Baru!')
    .with(NotificationTypeEnum.VendorReceiveOfferedMaterialPrice, () => 'Ajukan Harga Penawaran!')
    .with(NotificationTypeEnum.CustomerReceiveMaterialPrice, () => 'Informasi Harga Material!')
    .with(NotificationTypeEnum.AdminReceiveMaterialOrderRequest, () => 'Informasi Permintaan Material baru!')
    .with(NotificationTypeEnum.VendorReceiveMaterialOrderRequest, () => 'Informasi Permintaan Material baru!')
    .with(NotificationTypeEnum.ShippingMaterialStatus, () => 'Pengiriman telah diatur!')

    .with(NotificationTypeEnum.AdminGenerateInvoiceForDP, () => 'Tagihan uang muka')
    .with(NotificationTypeEnum.AdminGenerateInvoiceForTermin, () => 'Tagihan pembayaran termin')
    .with(NotificationTypeEnum.PaymentDpProject, () => 'Pembayaran uang muka')

    .with(NotificationTypeEnum.SubmittingBAST, () => 'Pengajuan dokumen Berita Acara Serah Terima (BAST)')
    .with(NotificationTypeEnum.SubmittingBAPP, () => 'Pengajuan dokumen Berita Acara Progress Pekerjaan (BAPP)')
    .with(NotificationTypeEnum.CustomerApproveBAST, () => 'Dokumen Berita Acara Serah Terima (BAST) disetujui!')
    .with(NotificationTypeEnum.CustomerApproveBAPP, () => 'Dokumen Berita Acara Progress Pekerjaan (BAPP) disetujui!')

    .with(NotificationTypeEnum.PartnerSendBASTBAPPToAdmin, () => 'Pengajuan dokumen BAST/BAPP')
    .with(NotificationTypeEnum.PartnerSendBASTToAdmin, () => 'Pengajuan dokumen Berita Acara Serah Terima (BAST)')
    .with(NotificationTypeEnum.PartnerSendBAPPToAdmin, () => 'Pengajuan dokumen Berita Acara Progress Pekerjaan (BAPP)')
    .with(NotificationTypeEnum.AdminApprovedBAST, () => 'Dokumen Berita Acara Serah Terima (BAST) disetujui!')
    .with(NotificationTypeEnum.AdminRejectBAST, () => 'Dokumen Berita Acara Serah Terima (BAST) ditolak!')
    .with(NotificationTypeEnum.AdminApprovedBAPP, () => 'Dokumen Berita Acara Progress Pekerjaan (BAPP) disetujui!')
    .with(NotificationTypeEnum.AdminRejectBAPP, () => 'Dokumen Berita Acara Progress Pekerjaan (BAPP) ditolak!')

    .with(NotificationTypeEnum.PartnerSendRABToCustomer, () => 'Pengajuan Dokumen Rancangan Anggaran Biaya (RAB)')

    .with(NotificationTypeEnum.GenerateLOP, () => 'Lampiran dokumen LOP')

    .with(NotificationTypeEnum.AdminGenerateInvoicePayingProjectBudget, () => 'Lampiran tagihan pelunasan')
    .with(NotificationTypeEnum.CustomerPayingProjectBudget, () => 'Pelunasan pembayaran proyek')
    .with(NotificationTypeEnum.AdminSendBudget, () => 'Transfer biaya proyek')

    .with(NotificationTypeEnum.PartnerAccountOpenToInProgress, () => 'Pengajuan Data Mitra Baru')
    .with(NotificationTypeEnum.PartnerAccountRejectedToInProgress, () => 'Pembaruan Data Baru')
    .with(NotificationTypeEnum.AdminSendPKSProject, () => 'Penandatanganan Dokumen Perjanjian Kerja Sama (PKS)')

    .with(NotificationTypeEnum.ValidityPeriodPksPartner, () => 'Pemberitahuan Masa Berlaku PKS Mitra X Admin')

    .with(NotificationTypeEnum.ConsultationAssigneeAdmin, () => 'Konsultasi Layanan SobatBangun Baru!')

    .with(NotificationTypeEnum.SigningPKSProjectToAdmin, () => 'Proyek Baru SobatBangun!')

    .otherwise(() => '-');

export const PaymentStatusMapper = (val?: string | unknown) =>
  match(val)
    .with(EnumPaymentStatus.CANCELED, () => 'Dibatalkan')
    .with(EnumPaymentStatus.EXPIRED, () => 'Kadaluwarsa')
    .with(EnumPaymentStatus.PAID, () => 'Telah dibayar')
    .with(EnumPaymentStatus.WAITING, () => 'Menunggu Pembayaran')
    .otherwise(() => '-');

export const SignedStatusMapper = (val?: string | unknown) =>
  match(val)
    .with('SIGNED', () => 'Sudah Ditandatangani')
    .with('UNSIGNED', () => 'Belum Ditandatangani')
    .with('EXPIRED', () => 'Kadaluwarsa')
    .with('WAITING_UPLOAD', () => 'Proses Unggah Dokumen ')
    .otherwise(() => '-');

type GetServerSidePropsHandler = <Props extends {}>(fnProps: () => Promise<Props>) => Promise<GetServerSidePropsResult<Props>>;

export const getServerSidePropsHandler: GetServerSidePropsHandler = async (fnProps) => {
  try {
    const props = await fnProps();
    return {
      props,
    };
  } catch (error: unknown) {
    if (!axios.isAxiosError(error)) throw new Error('Internal Server Error');
    if (error.response?.status === 401)
      return {
        redirect: {
          permanent: true,
          destination: '/',
        },
      };
    if (error.response?.status === 404) {
      return {
        notFound: true,
      };
    } else {
      throw new Error(error.response?.data?.error?.message || error.response?.data?.message || 'Internal Server Error');
    }
  }
};

export const getAuthorization = async (req: GetServerSidePropsContext['req'] | NextRequest | NextApiRequest) => {
  const token = await getToken({ req, secret: process.env.SECRET });
  return 'Bearer ' + token?.access_token;
};
