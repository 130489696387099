import { match } from 'ts-pattern';

export enum PartnerType {
  CRAFTMAN = 'CRAFTMAN',
  CONTRACTOR = 'CONTRACTOR',
  ARCHITECT = 'ARCHITECT',
  DEVELOPER = 'DEVELOPER',
  CONSTRUCTION_TECHNOLOGY = 'CONSTRUCTION_TECHNOLOGY',
}
export enum PartnerStatus {
  OPEN = 'OPEN',
  INPROGRESS = 'INPROGRESS',
  VERIFIED = 'VERIFIED',
  REJECTED = 'REJECTED',
}

export const PartnerStatusMapper = (val: string) =>
  match(val as PartnerStatus)
    .with(PartnerStatus.OPEN, () => 'Menunggu')
    .with(PartnerStatus.INPROGRESS, () => 'Dalam Proses')
    .with(PartnerStatus.VERIFIED, () => 'Disetujui')
    .with(PartnerStatus.REJECTED, () => 'Ditolak')
    .otherwise(() => '-');
