export enum EnumOrderStatus {
  OPEN = 'OPEN',
  ADMIN_MARKUP = 'ADMIN_MARKUP',
  TENDERING = 'TENDERING',
  PARTNER_SELECTED = 'PARTNER_SELECTED',
  VENDOR_SELECTED = 'VENDOR_SELECTED',
  ADMIN_APPROVED = 'ADMIN_APPROVED',
  CUSTOMER_APPROVED = 'CUSTOMER_APPROVED',
  CUSTOMER_REJECTED = 'CUSTOMER_REJECTED',
  PAID = 'PAID',
  UNPAID = 'UNPAID',
  SIGNING = 'SIGNING',
  SHIPPING = 'SHIPPING',
  COMPLETE = 'COMPLETE',
  CANCELED = 'CANCELED',
  INPROGRESS = 'INPROGRESS',
}

export enum EnumStatusOrderOffer {
  ACCEPTED_BY_CUSTOMER = 'ACCEPTED_BY_CUSTOMER',
  ACCEPTED_BY_ADMIN = 'ACCEPTED_BY_ADMIN',
  REJECTED_BY_CUSTOMER = 'REJECTED_BY_CUSTOMER',
  REJECTED_BY_ADMIN = 'REJECTED_BY_ADMIN',
  SEND_TO_CUSTOMER = 'SEND_TO_CUSTOMER',
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  CANCELED = 'CANCELED',
}

export enum EnumOrderMaterial {
  GENERAL = 'Non-Proyek',
  PROJECT = 'Proyek',
  GENERAL_OLD = 'MATERIAL_GENERAL',
  PROJECT_OLD = 'MATERIAL_PROJECT',
}

export enum EnumBankAccount {
  BNI = 'Bank Negara Indonesia',
  BCA = 'Bank Central Asia',
  MANDIRI = 'Bank Mandiri',
  PERMATA = 'Bank Permata',
  BRI = 'Bank Rakyat Indonesia',
  CIMB = 'Bank CIMB Niaga',
  BSI = 'Bank Syariah Indonesia',
}
