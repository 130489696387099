export enum EnumProjectType {
  Renovation = 'RENOVATION',
  BuildHouse = 'BUILD_HOUSE',
  Design = 'DESIGN',
  AdditionalTechnology = 'ADDITIONAL_TECHNOLOGY',
  Material = 'MATERIAL',
}

export enum EnumProjectStatus {
  INPROGRESS = 'INPROGRESS',
  DONE = 'DONE',
  CANCEL = 'CANCEL',
  OPEN = 'OPEN',
}

export enum EnumTerminCashType {
  CASH_IN = 'CASH_IN',
  CASH_OUT = 'CASH_OUT',
}
