export enum NotificationTypeEnum {
  ReferralUsed = 'REFERRAL USED',
  ReferralMaxUsed = 'REFERRAL MAXIMUM USED',

  ApprovedDocumentForAccountPartner = 'APPROVED DOCUMENT FOR ACCOUNT PARTNER',
  RejectedDocumentForAccountPartner = 'REJECTED DOCUMENT FOR ACCOUNT PARTNER',

  SigningPKSAdminToPartner = 'SIGNING PKS ADMIN TO PARTNER',
  SigningPKSPartnerToCustomer = 'SIGNING PKS PARTNER TO CUSTOMER',
  SigningPKSPartnerToAdmin = 'SIGNING PKS PARTNER TO ADMIN',

  AssignedNewOrder = 'ASSIGNED NEW ORDER ADMIN TO PARTNER',
  PartnerRejectOrder = 'PARTNER REJECT ASSIGNED ORDER FROM ADMIN',
  PartnerApproveOrder = 'PARTNER APPROVE ASSIGNED ORDER FROM ADMIN',
  BiddingClosed = 'BIDDING CLOSED',

  OfferingMaterialFromCustomer = 'OFFERING MATERIAL PRICE FROM CUSTOMER',
  OfferingMaterialFromPartner = 'OFFERING MATERIAL PRICE FROM PARTNER',
  VendorReceiveOfferedMaterialPrice = 'VENDOR RECEIVE OFFERED MATERIAL PRICE FROM ADMIN',
  CustomerReceiveMaterialPrice = 'CUSTOMER RECEIVE MATERIAL PRICE FROM ADMIN',
  AdminReceiveMaterialOrderRequest = 'ADMIN RECEIVE MATERIAL ORDER REQUEST FROM CUSTOMER',
  VendorReceiveMaterialOrderRequest = 'VENDOR RECEIVE MATERIAL ORDER REQUEST FROM ADMIN',
  ShippingMaterialStatus = 'SHIPPING MATERIAL STATUS',

  AdminGenerateInvoiceForDP = 'ADMIN GENERATE INVOICE FOR PAYING DP TO CUSTOMER',
  AdminGenerateInvoiceForTermin = 'ADMIN GENERATE INVOICE FOR PAYING TERMIN PHASE TO CUSTOMER',
  PaymentDpProject = 'PAYMENT DP PROJECT',

  SubmittingBAST = 'PARTNER SUBMITTING BAST TO CUSTOMER',
  SubmittingBAPP = 'PARTNER SUBMITTING BAPP TO CUSTOMER',
  CustomerApproveBAST = 'CUSTOMER APPROVE BAST',
  CustomerApproveBAPP = 'CUSTOMER APPROVE BAPP',

  PartnerSendBASTBAPPToAdmin = 'PARTNER SEND BAST BAPP TO ADMIN',
  PartnerSendBASTToAdmin = 'PARTNER SEND BAST TO ADMIN',
  PartnerSendBAPPToAdmin = 'PARTNER SEND BAPP TO ADMIN',
  AdminApprovedBAST = 'ADMIN APPROVE BAST TO PARTNER',
  AdminRejectBAST = 'ADMIN REJECT BAST TO PARTNER',
  AdminApprovedBAPP = 'ADMIN APPROVE BAPP TO PARTNER',
  AdminRejectBAPP = 'ADMIN REJECT BAPP TO PARTNER',

  PartnerSendRABToCustomer = 'PARTNER SEND RAB TO CUSTOMER',

  GenerateLOP = 'PARTNER GENERATE LOP',

  AdminGenerateInvoicePayingProjectBudget = 'ADMIN GENERATE INVOICE FOR PAYING PROJECT BUDGET',
  CustomerPayingProjectBudget = 'PAYING PROJECT BUDGET FROM CUSTOMER',
  AdminSendBudget = 'ADMIN SEND BUDGET TO PARTNER',

  PartnerAccountOpenToInProgress = 'PARTNER ACCOUNT OPEN TO IN PROGRESS',
  PartnerAccountRejectedToInProgress = 'PARTNER ACCOUNT REJECTED TO IN PROGRESS',

  AdminSendPKSProject = 'ADMIN SEND PKS PROJECT',

  ValidityPeriodPksPartner = 'VALIDITY PERIOD PKS PARTNER',

  ConsultationAssigneeAdmin = 'CONSULTATION ASSIGNEE ADMIN',

  SigningPKSProjectToAdmin = 'SIGNING PKS PROJECT TO ADMIN',
}
